<template>
<div>
  <form @submit.prevent="lookup" class="mb-3">
    <div class="row">
      <!-- add country selection -->
      <div class="col-md-9">
        <a-input v-model="number" placeholder="Telefonnummer" @change="checkNumber"/>
      </div>
      <div class="col-md-3">
        <button class="btn btn-primary text-nowrap" style="width: 100%" type="submit">
          Søk
        </button>
        </div>
    </div>
  </form>
  <p>{{ response }}</p>
  <p style="color: red" v-if="error">{{ error }}</p>
</div>
</template>

<script>
// import firebase from 'firebase'
import axios from 'axios'
export default {
  data () {
    return {
      number: '',
      response: '',
      error: '',
    }
  },
  methods: {
    lookup() {
      this.error = ''
      this.response = ''
      const api = '1608db86-e204-47c2-affd-64bbd4e57c31'
      const number = '47'.concat(this.number)
      const baseurl = 'https://api.data247.com/v3.0'
      const service = 'CI'
      const url = baseurl.concat('?key=', api, '&api=', service, '&phone=', number)
      console.log(url)
      axios.post(url).then(res => {
        console.log(res)
        this.error = res.data.response.message
        this.response = res.data.response.results[0].carrier_name
      })
      // firebase.functions().httpsCallable('carrierlookup',{})
      //   .then(res => {
      //     consle.log(res.data)
      //   })
    },
    checkNumber() {
      const regex = /\D/g
      let num = this.number
      num = num.trim()
      const cleanNum = num.replace(regex, '')
      this.number = cleanNum
    },
  },
}
</script>

<style>

</style>
